export const environment = {
  appUrl: 'https://staging.app.sbam.io',
  production: false,
  stage: 'staging',
  availableLanguages: ['en', 'it'],
  apiEndpoint: 'https://staging.api.sbam.io',
  v1: 'v1.0',
  facebookAppId: '355982238415529', // "Sbam - Test1" App Id
  // facebookAppId: '274916432960939', // "Sbam" App Id
  facebookApiVersion: 'v18.0',
  facebookScopes: [
    'email',
    'pages_manage_ads',
    'pages_manage_metadata',
    'pages_read_engagement',
    'pages_read_user_content',
    'read_insights',
    'instagram_basic',
    'instagram_manage_insights',
    'ads_read',
    'ads_management',
    'business_management',
    'instagram_manage_comments',
  ],
  linkedinAppKey: '77rwy4jtabe5rx',
  linkedinScopes: [
    'r_organization_social',
    'r_1st_connections_size',
    'r_ads_reporting',
    'r_emailaddress',
    'rw_organization_admin',
    'r_basicprofile',
    'r_ads',
  ],
  rollbarAccessToken: '9e637e82568f44de92a8d1a519083a60',
  forceUpdate: false,
  maintenance: false,
  stripePublicKey: 'pk_test_MNBAVK33vMjbdsRZqsu4VuWg00pxMwUrAn',
  mauticPublicKey: '',
  pusherAppKey: 'sbam_websockets_key',
  pusherAppCluster: 'mt1',
  wsEnabled: true,
  wsHost: 'staging.websockets.sbam.io',
  wsPort: 443,
}
